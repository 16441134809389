import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">        
        <picture>          
          <source 
            className="contain" 
            srcSet="/images/HoldPageGraphic.png 1920w, 
              /images/HoldPageGraphic@2048px.png 2048w, 
              /images/HoldPageGraphic@4096px.png 4096w, 
              /images/HoldPageGraphic.png 1x, 
              /images/HoldPageGraphic@2x.png 2x, 
              /images/HoldPageGraphic@3x.png 3x, 
              /images/HoldPageGraphic@4x.png 4x" 
            src="/images/HoldPageGraphic.png" alt="Tennisverse" />
            
          <img className="contain" src="/images/HoldPageGraphic.png" alt="Tennisverse" />
        </picture>
      </header>      
    </div>
  );
}

export default App;
